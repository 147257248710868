/* icons-Css */

.social_container {
  text-align: center;
  margin-top: 2px 0px 0px 0px;
  height: auto;
  }


/* a.social:hover {
  transform: translateY(-2px);
} */

.facebook {
  height: 25px;
  width: 25px;
  margin-right: 20px;
}

.insta {
  height: 25px;
  width: 25px;
  margin-right: 22px;
}

.whatsapp {
  height: 25px;
  width: 25px;
  margin-right: 20px;
}

.linkedin {
  height: 27px;
  width: 27px;
}

.text {
  text-align: justify;
  padding: 5px;
}

@media screen and (max-width: 480px) {
  .social_container {
    margin: 0;
    margin-top: 5px;
    margin-left: -6px;

  }
}