#Footer {
    background: linear-gradient(80deg, #F0EFF5 0%, #F0EFF5 100%);
    padding: 3.5rem 0rem 2.5rem 0rem;
}

.footer_container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 50px;
}

.brand_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    text-decoration: none;

}

.logo_img {
    height: 70px;
    width: auto;
    padding: 0%;
    margin: 0%;
}

.link {
    text-decoration: none;
    color: #000;
    font-weight: bold;
}

.underline {
    background-color: gray;
    height: .05rem;
    width: 100%;
    margin-top: 0px;
}

.copyright_text {
    text-align: center;
    font-size: .8rem;
    margin: 1rem 0px 0rem 0px;

}

@media screen and (max-width: 480px) {
    .footer_container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .nav {
        gap: 30px;
        margin-bottom: .8rem;
    }

}