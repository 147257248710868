.Navbar {
  margin-top: 30px;
  padding: 10px 5%;
  background-color: #ffffff;
  z-index: 0;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}

.header_text_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4px;
}

.header_text {
  font-size: 1rem; /* Changed from 'rem' to '1rem' for better readability */
  color: #ffffff;
}

/* Responsive logo */
.logo_img {
  max-height: 60px;
  max-width: 100%;
  height: auto;
}

.NavbarBrand {
  height: auto;
}

/* Navigation links */
.nav_links {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2rem;
  font-weight: bold;
 
}

.links {
  color: #000;
  margin-left: 30px;
  font-size: 1.2rem; /* Adjusted from 26px for better scaling */
}

/* Navigation button */
.nav_btn {
  border-radius: 30px;
  border: 1px solid lightgray;
  padding: 2px 25px;
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
}

/* Media Queries */

/* Mobile view */
@media only screen and (max-width: 480px) {
  .Navbar {
    padding: 10px 2%;
  }

  .header_text {
    font-size: 0.8rem; /* Adjusted font size for mobile */
  }

  .links {
    margin-left: 0px;
    font-size: 1rem; /* Adjusted font size for mobile */
  }

  .logo_img {
    max-height: 38px;
  }
}

/* Tablet view */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .Navbar {
    padding: 10px 3%;
  }

  .header_text {
    font-size: 0.9rem; /* Adjusted font size for tablets */
  }

  .links {
    font-size: 1.1rem; /* Adjusted font size for tablets */
  }

  .logo_img {
    max-height: 50px; /* Adjusted for tablets */
  }
}

/* Desktop view */
@media only screen and (min-width: 769px) {
  .Navbar {
    padding: 10px 5%;
  }

  .header_text {
    font-size: 1rem; /* Adjusted for desktops */
  }

  .links {
    font-size: 1.2rem; /* Adjusted for desktops */
  }

  .logo_img {
    max-height: 60px; /* Original height for desktops */
  }
}
