.about_container {
    padding: 0.5rem 15px 3.5rem 15px;
    margin-top: 8em;
}

.heading_para {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.heading3 {
    max-width: 16ch;
}

.about_text {
    max-width: 58ch;
    margin-top: 1rem;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.ipad_img {
    max-height: 600px;
}

.mobile_img {
    max-height: 800px;
    margin-top: 6rem;

}